/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
@import "~jsoneditor/dist/jsoneditor.min.css";

.ag-theme-alpine {
    --ag-border-color: #00000000;
    --ag-row-border-color: #00000000;
    --ag-odd-row-background-color: #F9F9F9;
    font-family: "Inter var", sans-serif;
}

//.ag-theme-alpine-dark {
//    --ag-background-color: #0E1628;
//    --ag-header-background-color: #0E1628;
//    --ag-odd-row-background-color: #0F172A;
//    --ag-border-color: #00000000; //#2A3243;
//    --ag-row-border-color: #0E1628;
//    font-family: "Inter var", sans-serif;
//
//    --ag-modal-overlay-background-color: #0E1628A8;
//}

.ag-theme-alpine-dark {
    --ag-background-color: #1E293B;
    --ag-header-background-color: #253042;
    --ag-odd-row-background-color: #242F41;
    --ag-border-color: #00000000; //#2A3243;
    //--ag-border-color: #494C54;
    --ag-row-border-color: #00000000;
    font-family: "Inter var", sans-serif;

    --ag-modal-overlay-background-color: #1E293BA8;
}

.ag--noborder {
    --ag-border-color: #00000000;
}

.ag--lightdarkborder {
    --ag-border-color: #1E293B;
}

.ag-center-cols-clipper {
    min-height: 40px !important;
}

snack-bar-container {
    //color: #FFFFFF !important;
    .mat-simple-snackbar-action {
        color: #4CAE50 !important;
    }
}

.error-snackbar{
    color: #FFFFFF !important;
    background: #D85656 !important;
    .mat-simple-snackbar-action {
        color: #FFFFFF !important;
    }
}

.panel-override {
    max-height: 60vh !important;
    margin-top: 35px !important;
}

.jsoneditor {
    border: thin solid #D3D3D3;
}

.outerjsoneditor-dark > div > .jsoneditor {
    border: thin solid #94A39C;
}

div.jsoneditor-menu {
    background-color: #4CAE50;
    border-bottom: 1px solid #D3D3D3;
}

div.jsoneditor {
    height: 35rem
}

.mat-tab-labels {
    justify-content: center;
}

.disabledlabel {
    color: #7F858F
}

.disabledlabel-dark {
    color: #BABEC4
}

.json-textarea {
    font-family: monospace;
    resize: none;
}

.css-field-error {
    border-color: var(--fuse-warn);
}

#preview {
    width: 100%;
    height: 100%;
}
